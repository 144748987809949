import { INDEXING_METRICS_DIMENSIONS } from "../../Collection/Dashboards/constants";

export interface GraphDefinition {
    title: string;
    paramSets: Array<{
        stats: string[];
        metricNames: string[];
        unit?: string;
        isRootAccount?: boolean;
    }>;
    mathFunctionParams?: Array<{
        id?: string;
        expression: string;
        label: string;
    }>;
}

export interface MetricDefinitions {
    namespace: string;
    dimensionNames: any;
    graphs: GraphDefinition[];
}

export enum OCU_METRICS_DIMENSIONS {
    AWS_ACCOUNT_ID = 'AwsAccountId',
}

export const OCU_METRICS: MetricDefinitions = {
    namespace: 'AOSSBeholder/KAM/CxMetrics',
    dimensionNames: OCU_METRICS_DIMENSIONS,
    graphs: [
        {
            title: 'Search OCU History',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['SearchOCU'],
                    unit: 'Count',
                },
            ],
        },
        {
            title: 'Indexing OCU History',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['IndexingOCU'],
                    unit: 'Count',
                },
            ],
        },
    ],
};

export enum SEARCH_NODESTATS_DIMENSIONS {
    AWS_ACCOUNT_ID = "aws_account_id",
    METRIC_TYPE = "metric_type",
};

export const SEARCH_WORKER_METRICS: MetricDefinitions = {
    namespace: 'JunoSearch/Worker',
    dimensionNames: SEARCH_NODESTATS_DIMENSIONS,
    graphs: [
        {
            title: 'Shard State',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['SyncingShardCount', 'SyncedShardCount', 'UnassignedShardCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Disk Space',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['DiskTotalSpace', 'DiskAvailableSpace',
                        'DiskAssignmentLimitGb', 'DiskAssignmentAvailableGb',
                        'DiskOldSegmentDataGb', 'DiskFreeSpace'],
                    unit: 'Gigabytes',
                }
            ],
        },
        {
            title: 'Disk Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['DiskUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['DiskUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['DiskUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'Memory Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'JVM Heap Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'Container CPU Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['ContainerCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['ContainerCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['ContainerCPUUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'Task CPU Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
             title: 'Search Thread Pool Queue',
             paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['ThreadPool.search.Queue'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['ThreadPool.search.Queue'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['ThreadPool.search.Queue'],
                    unit: 'Count',
                }                 
             ]
        },
        {
            title: 'JVM Threads',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Counts',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMGCYoungCollectionCount', 'JVMGCOldCollectionCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Times',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['JVMGCYoungCollectionTime', 'JVMGCOldCollectionTime'],
                    unit: 'Milliseconds',
                }
            ]
        },
        {
            title: 'JVM Class Counts',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Open File Descriptors',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'HTTP Connections',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport RX Count',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport TX Size',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                }
            ]
        }
    ],
};

export const SEARCH_COORDINATOR_METRICS: MetricDefinitions = {
    namespace: 'JunoSearch/Coordinator',
    dimensionNames: SEARCH_NODESTATS_DIMENSIONS,
    graphs: [
        {
            title: 'Disk Space',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['DiskTotalSpace', 'DiskAvailableSpace'],
                    unit: 'Gigabytes',
                }
            ],
        },
        {
            title: 'Memory Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'JVM Heap Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'CPU Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                }
            ]
        },
        {
            title: 'JVM Threads',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMThreadsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Counts',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMGCYoungCollectionCount', 'JVMGCOldCollectionCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'JVM GC Collection Times',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['JVMGCYoungCollectionTime', 'JVMGCOldCollectionTime'],
                    unit: 'Milliseconds',
                }
            ]
        },
        {
            title: 'JVM Class Counts',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMClassesCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Open File Descriptors',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'HTTP Connections',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport RX Count',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                }
            ]
        },
        {
            title: 'Transport TX Size',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TransportTXSize'],
                    unit: 'GB',
                }
            ]
        }
    ],
};


export enum INDEXING_DIMENSIONS {
    AWS_ACCOUNT_ID = "AwsAccountId",
    METHOD_NAME = "MethodName",
    SERVICE_NAME = "ServiceName",
}

export const BULK_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Bulk Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const BULK_ITEM_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'BulkItem Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BulkItemSuccess','BulkItemFailure','BulkItemFault'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const BULK_SHARD_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'BulkShard Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const ROLLOVER_INDEXING_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Rollover Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success','Fault','Failure'],
                    unit: 'Count',
                },
            ],
        }
    ],
};

export const INDEXING_COORDINATOR_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingCoordinator',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Coordinator Task CPU Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['TaskCPUUtilization'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Coordinator Disk Space Used Percentage',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['DiskSpaceUsedPercentage'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['DiskSpaceUsedPercentage'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['DiskSpaceUsedPercentage'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Coordinator HTTP Connections Count',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Coordinator JVM Heap Utilization',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['JVMHeapUtilization'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Admission Control Global CPU Usage Controller Rejected',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['AdmissionControlGlobalCpuUsageControllerRejected'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Admission Control Global Jvmmp Controller Rejected',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['AdmissionControlGlobalJvmmpControllerRejected'],
                    unit: 'Count',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_BULK_SHARD_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
         {
            title: 'Index Update Delete Ops',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['IndexOps', 'UpdateOps', 'DeleteOps'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Bulk Create Update Delete Latencies',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['CreateRequestLatency', 'UpdateRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'BULK SHARD Block Fetch Success',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchSuccess'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchFailure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['BlockFetchTime'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Indexing Memory Controller Throttle Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['IndexingMemoryControllerThrottleCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Indexing Memory Controller Throttle Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['IndexingMemoryControllerThrottleTime'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Bulk Shard Request Count',
            paramSets: [
                {
                    stats: ['SampleCount'],
                    metricNames: ['Failure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Bulk Shard Request Client Errors',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['ClientError'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Bulk Shard Request Faults',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Fault'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Bulk Shard S3 Put P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['S3PutRequestsLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Bulk Shard S3 Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Exceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Bulk Shard All S3 Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Requests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Bulk Shard DynamoDB Request P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Bulk Shard DynamoDB Request P90 Latency',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Bulk Shard DynamoDB Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBExceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Bulk Shard All DynamoDB Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBRequests'],
                    unit: 'Count',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_SHARD_UTILIZATION_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Uncommitted Translog Operation Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['UncommittedTranslogOperationCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Is Block Level Fetch Enabled',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['IsBlockLevelFetchEnabled'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Doc Update Percentage',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['DocUpdatePercentage'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Hydration Cool Down',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['HydrationCoolDown'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Hydration Status Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['CurrentStatusHydrated', 'CurrentStatusUnhydrated', 'CurrentStatusHydrationInProgress', 'CurrentStatusUnhydrationInProgress', 'DesiredStatusHydrated', 'DesiredStatusUnhydrated'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Working Set & Shard Disk Space Utilization',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['ShardWorkingSet', 'ShardDiskSpaceUtilization'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Graph Index Memory Local',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['GraphIndexMemoryLocal'],
                    unit: 'Bytes',
                },
                {
                    stats: ['Average'],
                    metricNames: ['GraphIndexMemoryLocal'],
                    unit: 'Bytes',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['GraphIndexMemoryLocal'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Graph Index Memory Total',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['GraphIndexMemoryTotal'],
                    unit: 'Bytes',
                },
                {
                    stats: ['Average'],
                    metricNames: ['GraphIndexMemoryTotal'],
                    unit: 'Bytes',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['GraphIndexMemoryTotal'],
                    unit: 'Bytes',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_SHARD_MANAGEMENT_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Shard Recovery Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['SHARD_TIME_TO_RECOVERY'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Shards Recovered',
            paramSets: [
                {
                    stats: ['SampleCount'],
                    metricNames: ['SHARD_TIME_TO_RECOVERY'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'SHARD TIME TO TRANSLOG REPLAY',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['SHARD_TIME_TO_TRANSLOG_REPLAY'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'SHARD MANAGEMENT Block Fetch Success',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchSuccess'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchFailure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['BlockFetchTime'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Prefetch Blocks Failed',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['PrefetchBlocksFailed'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Prefetch Bytes Failed',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['PrefetchBytesFailed'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Prefetch Estimated Bytes',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['PrefetchEstimatedBytes'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Engine Creation Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['ENGINE_CREATION_TIME'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Shard Assignment Requests',
            paramSets: [
                {
                    stats: ['SampleCount'],
                    metricNames: ['SHARD_ASSIGNMENT.Fault'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Assignment Client Errors',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_ASSIGNMENT.ClientError'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Assignment Faults',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_ASSIGNMENT.Fault'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Assignment Successes',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_ASSIGNMENT.Success'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Assignment Failure Conditional Check Failed',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_ASSIGNMENT.FailureConditionalCheckFailed'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Recovery Time Metric',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['SHARD_TIME_TO_RECOVERY'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Worker Shard Created',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_CREATED'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Worker Shard Unassigned',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_UNASSIGNED'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Worker Shard Synced',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_SYNCED'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Worker Shard Assignment Active',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_ASSIGNMENT.ACTIVE'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Worker Shard Assignment Shadow',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_ASSIGNMENT.SHADOW'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Management S3 Put P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['S3PutRequestsLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Management S3 Put P90 Latency',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['S3PutRequestsLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Management S3 Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Exceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Management All S3 Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Requests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Management DynamoDB Request P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Management DynamoDB Request P90 Latency',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Management DynamoDB Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBExceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Management All DynamoDB Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBRequests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Sync Success',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_SYNCED'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Sync Faults',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_FAULT'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Time To S3 Recovery Success',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['SHARD_TIME_TO_S3_RECOVERY_SUCCESS'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Shard Fault',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_FAULT'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['SHARD_FAILURE'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Update Cold Start Latency',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['WARM_SHARD_ASSIGNMENT_TIME'],
                    unit: 'Seconds',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_COLLECTOR_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Worker CPU Utilizations',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['CPUUtilization'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Worker Memory Utilizations',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['MemoryUtilization'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Worker Disk Utilizations',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['DiskSpaceUtilizationPercentage'],
                    unit: 'Percent',
                },
                {
                    stats: ['Average'],
                    metricNames: ['DiskSpaceUtilizationPercentage'],
                    unit: 'Percent',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['DiskSpaceUtilizationPercentage'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Disk Throughput Metrics',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['DiskReadThroughputPercentage', 'DiskWriteThroughputPercentage', 'DiskThroughputPercentage'],
                    unit: 'Percent',
                }
            ],
        },
        {
            title: 'Total Disk Read/Write Operations',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['TotalDiskReadOperations', 'TotalDiskWriteOperations'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Max Disk Throughput KBPS',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['MaxDiskThroughputKBPS'],
                    unit: 'KilobytesPerSecond',
                }
            ],
        },
        {
            title: 'Active Shard Count & Primary Shard Count',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['ActiveShardCount', 'PrimaryShardCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'File Cache Usage Metrics',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['FileCacheFilesEvicted', 'FileCacheUsage', 'FileCacheActiveUsage', 'FileCachePinnedUsage', 'FileCacheBlockedUsage'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Pending Clean Metrics',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['PendingCleanRunnables', 'PendingCleanMultiRunnables'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Vm MMap File Count',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['VmMMapFileCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Absolute Memory Utilization',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['NonHeapMemoryUsage'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Cache Stats Global Hit Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['CacheStatsGlobalHitCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Cache Stats Global Miss Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['CacheStatsGlobalMissCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Cache Stats Global Eviction Weight',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['CacheStatsGlobalEvictionWeight'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Cache Stats Global Forced Eviction Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['CacheStatsGlobalForcedEvictionCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Cache Stats Global Recent Eviction Ttl Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['CacheStatsGlobalRecentEvictionTtlCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Open File Descriptors',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['OpenFileDescriptors'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Max File Descriptors',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['MaxFileDescriptors'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'HTTP Connections Count',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['HTTPConnectionsCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Transport Outbound Connections',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['TransportOutboundConnections'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Transport RX Count',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['TransportRXCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Transport TX Count',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['TransportTXCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Transport RX Size',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['TransportRXSize'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Transport TX Size',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['TransportTXSize'],
                    unit: 'Bytes',
                }
            ],
        }  
    ], 
};

export const INDEXING_WORKER_MERGE_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Merge Size',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['MergeSize'],
                    unit: 'Bytes',
                }
            ],
        },
        {
            title: 'Merge Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['Time'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Indexing Merge Throttle Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['IndexingMergeThrottleCount'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Indexing Merge Throttle Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['IndexingMergeThrottleTime'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'MERGE Block Fetch Success',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchSuccess'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchFailure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['BlockFetchTime'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Refresh Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['Time'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Failure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Fault',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Fault'],
                    unit: 'Count',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_REFRESH_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
         {
            title: 'Shard Refresh S3 Put P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['S3PutRequestsLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Refresh S3 Put P90 Latency',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['S3PutRequestsLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Refresh DynamoDB Request P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Refresh DynamoDB Request P90 Latency',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Shard Refresh S3 Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Exceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Refresh DynamoDB Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBExceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Refresh All S3 Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Requests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Refresh All DynamoDB Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBRequests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Refresh Client Errors',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['ClientError'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Refresh Faults',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Fault'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Shard Refresh Successes',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['Success'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'REFRESH Block Fetch Success',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchSuccess'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchFailure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['BlockFetchTime'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Refresh Failure Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['REFRESH_FAILURE_COUNT'],
                    unit: 'Count',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_FLUSH_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Flush S3 Put P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['S3PutRequestsLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Flush S3 Put P90 Latency',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['S3PutRequestsLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Flush S3 Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Exceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Flush All S3 Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllS3Requests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Flush DynamoDB Request P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Flush DynamoDB Request P90 Latency',
            paramSets: [
                {
                    stats: ['p90'],
                    metricNames: ['DynamoDBRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Flush DynamoDB Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBExceptions'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Flush All DynamoDB Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllDynamoDBRequests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Flush Kinesis Request P99 Latency',
            paramSets: [
                {
                    stats: ['p99'],
                    metricNames: ['KinesisRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        },
        {
            title: 'Flush All Kinesis Requests',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllKinesisRequests'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Flush All Kinesis Exceptions',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['AllKinesisExceptions'],
                    unit: 'Count',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_UPDATE_SHARD_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Shadow To Primary Promotion',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['SHARD_TIME_TO_PROMOTION'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'TRANSLOG OPERATIONS RECOVERED',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['TRANSLOG_OPERATIONS_RECOVERED'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Prefetch Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['PrefetchTime'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'S3 Upload Byte Count',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['S3UploadByteCount'],
                    unit: 'Bytes',
                }
            ],
        }
    ],
};

export const INDEXING_WORKER_HYDRATION_METRICS: MetricDefinitions = {
    namespace: 'Amazon/JunoIndexingWorker',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Hydration Success',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['HydrationSuccess'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Hydration Duration',
            paramSets: [
                {
                    stats: ['Maximum'],
                    metricNames: ['HydrationDuration'],
                    unit: 'Seconds',
                }
            ],
        },
        {
            title: 'Hydration Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['HydrationFailure.FailureBlockDownloadFailed', 'HydrationFailure.FailureConcurrentHydrationLimitBreached', 'HydrationFailure.FailureCoolDownDueToPreviousFailures', 'HydrationFailure.FailureDiskThresholdBreached', 'HydrationFailure.FailureWorkingSetComputeFailure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'HYDRATION Block Fetch Success',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchSuccess'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Failure',
            paramSets: [
                {
                    stats: ['Sum'],
                    metricNames: ['BlockFetchFailure'],
                    unit: 'Count',
                }
            ],
        },
        {
            title: 'Block Fetch Time',
            paramSets: [
                {
                    stats: ['Average'],
                    metricNames: ['BlockFetchTime'],
                    unit: 'Milliseconds',
                }
            ],
        }
    ],
};

export const SERVICE_GATEWAY_METRICS: MetricDefinitions = {
    namespace: 'JunoServiceGateway-metrics',
    dimensionNames: INDEXING_DIMENSIONS,
    graphs: [
        {
            title: 'Service Request Latency',
            paramSets: [
                {
                    stats: ['Minimum'],
                    metricNames: ['ServiceRequestLatency'],
                    unit: 'Milliseconds',
                },
                {
                    stats: ['Average'],
                    metricNames: ['ServiceRequestLatency'],
                    unit: 'Milliseconds',
                },
                {
                    stats: ['Maximum'],
                    metricNames: ['ServiceRequestLatency'],
                    unit: 'Milliseconds',
                }
            ],
        }
    ],
};

export const METRIC_RETENTION_INFO =[
    "Data points with a period of less than 60 seconds are available for 3 hours",
    "Data points with a period of 60 seconds (1 minute) are available for 15 days",
    "Data points with a period of 300 seconds (5 minute) are available for 63 days",
    "Data points with a period of 3600 seconds (1 hour) are available for 455 days (15 months)"
]

const DAY_TO_HOURS = 24
const HOURS_TO_SECONDS = 3600
const MINUTE_TO_SECONDS = 60

export const HOURS_3 = 3 * HOURS_TO_SECONDS
export const DAYS_15 = 15 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_63 = 63 * DAY_TO_HOURS * HOURS_TO_SECONDS
export const DAYS_455 = 455 * DAY_TO_HOURS * HOURS_TO_SECONDS

export const SEC_1 = 1
export const MINUTE_1 = 1 * MINUTE_TO_SECONDS
export const MINUTE_5 = 5 * MINUTE_TO_SECONDS
export const MINUTE_15 = 15 * MINUTE_TO_SECONDS
export const HOUR_1 = 1 * HOURS_TO_SECONDS
export const DAY_1 = 1 * DAY_TO_HOURS * HOURS_TO_SECONDS
